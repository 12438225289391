@import "../themes.scss";

.customer-page {
  @include mobile-small {
    width: 500px !important;
  }

  @include mobile {
    width: 530px !important;
  }

  @include mobile-large {
    width: 550px !important;
  }

  @include tablet {
    width: 768px !important;
  }

  .text-field {
    font-family: $font-body;
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
    }

    .MuiInputLabel-root {
      color: black !important;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    :hover .MuiOutlinedInput-input {
      color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }

    :hover .MuiInputLabel-root {
      color: black;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }
  }

  .btn-filter {
    .MuiAutocomplete-option {
      font-family: $font-body !important;
      font-weight: bold;
    }
  }

  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }

  .page-title {
    color: $color-member-white;
    font-size: 22px;
    font-family: $font-title;
    font-weight: bold;
  }

  .icon-slash {
    color: $color-member-white;
    font-size: 15;
  }

  .sub-title {
    text-align: center;
    font-size: $font-size-note;
    font-family: $font-body;
  }
  .stack-btn {
    .btn-add {
      border-radius: 10px;
      text-transform: none;
      background-color: $primary-color;
      color: #fff;
      width: 100px;
      padding: 10px;

      .style-add {
        color: $gray-color;
        font-family: $font-body;
        font-weight: bold;
      }

      .btn-text {
        color: #fff;
        font-family: $font-body;
        font-size: $font-size-note;
      }
    }
  }

  .btn-search {
    .btn-text-field {
      display: flex;
      align-items: center;
      width: 300px;
      background-color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }
  }

  .btn-filter {
    width: 18%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-controll-select {
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiMenuItem-gutters {
        font-family: $font-body;
      }
    }
  }

  .container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .pagination {
      margin-top: 15px;
      justify-content: right;
      padding: 0px 30px;
    }

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;

      .header-row {
        border: 0.2px solid $black-color;
        .header-title {
          padding: 10px 10px;
          border-bottom: 1px solid $primary-color;
          font-size: $font-size-note;
          color: $black-color;
          font-family: $font-title;
          font-weight: bold;
        }
      }

      .body-active {
        box-shadow: none;
        background-color: $background-hover;
        cursor: pointer;

        .body-bold {
          padding: 6px 10px;
          font-size: $font-Size-Subtitle;

          .icon-maker {
            font-size: 11;
            color: $primary-color;
          }

          .text {
            font-size: $font-Size-body;
            font-family: $font-body;
            color: $primary-color;
            font-weight: bold;
          }
        }

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;

          cursor: pointer;

          .status-pending {
            color: $color-member-O;
            font-weight: bold;
            font-size: $font-size-note;
          }

          .status-approve {
            color: $color-member-G;
            font-weight: bold;
            font-family: $font-body;
            font-size: $font-size-note;
            text-align: center;
          }

          .status-rejected {
            color: $color-member-R;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }

          .status-instock {
            color: $primary-color;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }

          .status-voided {
            color: $color-member-R;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }
        }
      }

      .body {
        box-shadow: none;
        background-color: $background-hover;
        

        .body-bold {
          padding: 6px 10px;
          font-size: $font-size-note;

          .icon-maker {
            font-size: 11;
            color: $primary-color;
          }

          .text {
            font-size: $font-size-note;
            font-family: $font-body;
            color: $primary-color;
            font-weight: bold;
          }
        }

        
        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-OS-Siem-Reap;

          cursor: pointer;

          .status-pending {
            color: $color-member-O;
            font-weight: bold;
          }

          .status-approve {
            color: $color-member-G;
            font-weight: bold;
            font-family: $font-body;
            font-size: $font-size-note;
            text-align: center;
          }

          .status-rejected {
            color: $color-member-R;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }

          .status-instock {
            color: $primary-color;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }

          .status-voided {
            color: $color-member-R;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }
        }
      }

      .body-status {
        background-color: red;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }

      .body-odd {
        box-shadow: none;
        cursor: pointer;
        background-color: #fff;


        .body-bold {
          padding: 6px 10px;
          font-size: $font-Size-Subtitle;

          .icon-maker {
            font-size: 11;
            color: $primary-color;
          }

          .text {
            font-size: $font-Size-body;
            font-family: $font-body;
            color: $primary-color;
            font-weight: bold;
          }
        }

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-OS-Siem-Reap;

          .status-pending {
            color: $color-member-O;
            font-family: $font-body;
            font-weight: bold;
            font-size: $font-size-note;
          }

          .status-approve {
            color: $color-member-G;
            font-family: $font-body;
            font-size: $font-size-note;
            font-weight: bold;
          }

          .status-rejected {
            color: $color-member-R;
            font-family: $font-body;
            font-weight: bold;
            font-size: $font-size-note;
          }

          .status-instock {
            color: $primary-color;
            font-family: $font-body;
            font-weight: bold;
            font-size: $font-size-note;
          }

          .status-voided {
            color: $color-member-R;
            font-family: $font-body;
            font-weight: bold;
            font-size: $font-size-note;
          }
        }

        .body-status {
          background-color: red;

          .body-title {
            padding: 6px 10px;
            font-size: $font-size-note;
            font-family: $font-body;
          }
        }
      }
    }
  }
}
