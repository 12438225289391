@import "../../../themes.scss";

.ofuser {
  width: 100%;
  padding: 20px;

  .main-title {
    color: $primary-color;
    font-size: $font-size-articles;
    font-family: $font-title;
    font-weight: bold;
    padding: 10px;
  }

  .number {
    color: $primary-color;
    text-align: center;
    font-weight: bold;
    font-family: $font-body;
  }

  .sub-title{
    font-family: $font-body;
    font-size: $font-size-small;
    text-align: center;
  }

  .grid-group {
    width: 100%;    
    height: 100%;

      .card-user {
        width: 100%;
        background-color: #f8f8f8;
        border-radius: 10px;  
        padding: 20px;
        display: flex;
        justify-content: center;      
        
      }

  }

}
