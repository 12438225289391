@import '../themes.scss';

.login-page {
    width: 100%;
    height: 100vh;
    // background-color: $background-menu;

    .text-field {
        .MuiOutlinedInput-input {
            color: white !important;
            font-family: $font-body;
            font-size: 13px;
        }

        .MuiInputLabel-root {
            color: white !important;
        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color;
        }

        :hover .MuiOutlinedInput-input {
            // color: #fff;
            font-family: $font-body;
        }

        :hover .MuiInputLabel-root {
            color: white;
        }

        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: #0B82C4;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
            color: white;
        }

        .MuiInputLabel-root.Mui-focused {
            color: white;
            font-family: $font-body;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #0B82C4;
        }
    }


    .background-image {
        background-image: url("../Assets/backgroundImages.png");
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: 50% 50%;
        position: fixed;
        opacity: 0.2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

    }

    .container {
        width: 100%;
        height: 95%;
        position: relative;
        display: flex;
        justify-content: center;

        .login-container {
            width: 320px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .login {
                border-radius: 15px;
                width: 100%;
                padding: 25px 0px 25px 0px;
                background-color: $background-color-green;


                .box-logo {
                    display: flex;
                    justify-content: center;
                }

                .box-text {
                    display: flex;
                    justify-content: center;

                    .title {
                        color: $font-color;
                        font-family: $font-title;
                    }

                    .sub-title {
                        color: #fff;
                    }
                }

                .box-login {
                    display: flex;
                    justify-content: center;

                    .field-email {
                        padding: 5px 6px 5px 6px;
                        background-color: $primary-color;
                        border-radius: 15px;

                        .icon {
                            color: #fff;
                        }
                    }

                    .btb-sign-in {
                        border-radius: 15px;
                        background-color: $primary-color;
                        font-family: $font-title;
                        color: #fff;
                        text-transform: none;
                        font-size: 18px;
                    }
                }

                .forgot-password {
                    font-family: $font-body;
                    color: $primary-color;
                }
            }

        }




    }

    .footer {
        font-family: "Siemreap" !important;
        white-space: normal;
    }



}