@import "../themes.scss";

.supplies-page {
  width: 100%;

  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
      border-radius: 10px;
    }

    .MuiInputLabel-root {
      color: black !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    :hover .MuiOutlinedInput-input {
      color: #fff;
      font-family: $font-body;
      border-radius: 10px;
    }

    :hover .MuiInputLabel-root {
      color: black;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }
  }

  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }

  .color {
    color: $color-member-white;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .stack-btn {
    .btn-add {
      text-transform: none;
      border-radius: 10px;
      background-color: $primary-color;
      color: #fff;
      width: 100px;
      padding: 10px;

      .btn-text {
        color: #fff;
        font-size: $font-size-note;
        font-family: $font-body;
        // font-weight: bold;
      }

      .btn-icon {
        color: $primary-color;
      }
    }
  }

  .btn-search {
    .btn-text-field {
      display: flex;
      align-items: center;
      width: 300px;
      background-color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }
  }

  .container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .pagination {
      margin-top: 15px;
      justify-content: right;
      padding: 0px 30px;
    }


    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;

      @include mobile-small {
        width: 500px !important;
      }

      @include mobile {
        width: 530px !important;
      }

      @include mobile-large {
        width: 550px !important;
      }

      @include tablet {
        width: 768px !important;
      }

      .header-row {
        .header-title {
          padding: 10px 10px;
          border-bottom: 1px solid $primary-color;
          font-size: $font-size-note;
          color: $black-color;
          font-family: $font-title;
          font-weight: bold;
        }
      }

      .body {
        box-shadow: none;
        cursor: pointer;
        background-color: $background-hover;
        

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;

          .avatar {
            width: 40px;
            height: 40px;
            text-align: left;
          }

          .product-name {
            place-items: center;
            display: flex;
            width: 100%;
          }

          .status-Pending {
            font-weight: bold;
            color: red;
          }

          .status-Rejected {
            font-weight: bold;
            color: #006fff;
          }

          .status-Approval {
            font-weight: bold;
            color: #20d819;
          }
        }
      }



      .body-odd {
        box-shadow: none;
        cursor: pointer;
        background-color: #fff;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          .avatar {
            width: 40px;
            height: 40px;
            text-align: left;
          }

          .product-name {
            place-items: center;
            display: flex;
            width: 100%;
          }

          .status-Pending {
            font-weight: bold;
            color: red;
          }

          .status-Rejected {
            font-weight: bold;
            color: #006fff;
          }

          .status-Approval {
            font-weight: bold;
            color: #20d819;
          }
        }
      }
    }
  }
}
