@import "../themes.scss";

.unit_page {
  width: 100%;

  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
    }
    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
      font-family: $font-body;
    }
    :hover .MuiInputLabel-root {
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }
  
  .page-title {
    color: $color-member-white;
    font-size: 22px;
    font-family: $font-title;
    font-weight: bold;
  }

  .btn-search {
    border-radius: 5px;
    background-color: white;

    .btn-text-field {
      display: flex;
      align-items: center;
      width: 300px;
      background-color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }
  }

  .stack-btn {
    .btn-add {
      text-transform: none;
      border-radius: 10px;
      background-color: $primary-color;
      color: #fff;
      padding: 10px;
      width: 100px;

      .btn-text {
        color: #fff;
        font-family: $font-body;
      }
    }
  }

  .container {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;

      .header-row {
        border: 0.2px solid $black-color;
        .header-title {
          padding: 10px 10px;
          border-bottom: 1px solid $primary-color;
          // border: none;
          font-size: $font-size-note;
          font-family: $font-title;
          color: $black-color;
          font-weight: bold;
        }
      }

      .body {
        box-shadow: none;
        cursor: pointer;

        background-color: $background-hover;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
        }
        .body-english {
          padding: 6px 10px;
          font-size: $font-size-articles;
          font-family: $font-body;
        }
      }

      .body-odd {
        box-shadow: none;
        cursor: pointer;
        background-color: #fff;

        :hover {
          background-color: $background-hover;
        }

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
        }
        .body-english {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }
    }
  }
}
