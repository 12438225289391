@import "../../themes.scss";

.recieve {
  @include mobile-small {
    width: 400px !important;
  }
  @include mobile {
    width: 430px !important;
  }
  @include mobile-large {
    width: 450px !important;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: $background-menu;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;
  height: 550px;
  overflow: scroll;

  .header-title {
    font-family: $font-body;
    font-size: $font-size-note;
    font-weight: bold;
    color: $black-color;
  }
  .sub-title {
    font-family: $font-body;
  }

  .text-typography {
    font-family: $font-body;
    font-size: $font-size-note;
    font-weight: bold;
    color: $black-color;
  }
  .title {
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }
  .list-style {
    width: 100%;
    margin-top: 5px;
    .list-item {
      width: 50%;
      .box-list {
        background-color: $color-text-field;
        height: auto;
      }
    }
  }

  .container {
    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        .header-title {
          padding: 5px;
          border: none;
          font-size: $font-Size-Subtitle;
        }
      }
      .font-total {
        font-weight: bold;
        font-family: $font-title;
      }

      .body {
        box-shadow: none;

        .body-row {
          background-color: $color-text-field;
          .slash {
            width: 4px;
            height: 45px;
            background-color: $primary-color;
          }
          .body-title {
            padding: 10px;
            border: none;
            font-size: $font-Size-Subtitle;

            .status {
              font-weight: bold;
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .add-item {
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
    }

    .text-btn {
      color: $background-menu;
      font-family: $font-title;
      font-size: $font-size-articles;
    }
  }

  .btn-priority {
    background-color: $primary-color !important;
    color: $background-menu;
    font-family: $font-title;
  }
  .btn-err {
    background-color: $color-member-R !important;
    color: $background-menu;
    font-family: $font-title;
  }

  .body-title {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
    font-weight: bold;
  }

  .body {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
    font-weight: bold;
    color: $primary-color;
  }

  .btn-filter {
    .MuiAutocomplete-option {
      font-family: $font-body !important;
      color: black !important;
    }
  }
  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-OS-Siem-Reap !important;
    }
    .MuiInputLabel-root {
      color: black !important;
      font-family: $font-OS-Siem-Reap !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-OS-Siem-Reap !important;
    }
    :hover .MuiOutlinedInput-input {
      font-family: $font-OS-Siem-Reap !important;
    }
    :hover .MuiInputLabel-root {
      font-family: $font-OS-Siem-Reap !important;
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      // border-color: #fff;
      font-family: $font-OS-Siem-Reap !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-OS-Siem-Reap !important;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-OS-Siem-Reap !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      // border-color: #fff;
      font-family: $font-OS-Siem-Reap !important;
    }
  }

  .form-controll-select-status {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }

    .MuiOutlinedInput-input {
      font-family: $font-body !important;
    }
    .MuiTypography-root {
      font-family: $font-body !important;
    }

    .MuiSelect-icon {
      color: $gray-color;
      font-size: 28px;
    }
  }

  .selection {
    .MuiOutlinedInput-input {
      font-family: $font-body !important;
      border: 0px;
      box-shadow: none;
    }
  }

  .view-btn {
    width: 50%;
    justify-content: center;
  }

  .btn-create {
    color: white;
    width: 100%;
    font-size: $font-size-note;
    font-family: $font-title;
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
    }
  }

  .btn-reject {
    background-color: $color-member-R;
    width: 100px;
    &:hover {
      background-color: $color-member-R;
    }
    color: white;
  }

  .btn-app {
    background-color: $primary-color;
    width: 100px;
    &:hover {
      background-color: $primary-color;
    }
    color: white;
  }
}
