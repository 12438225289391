@import '../../themes.scss';

.unit-update {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;    
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;
    .text-field {
            
        .MuiOutlinedInput-input {
            color: black !important;
            font-family: $font-body;
            font-size: $font-size-articles;
        }   
        .MuiInputLabel-root {
            color: black !important;
        
        }        
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }        
        :hover .MuiOutlinedInput-input {
            color: black;
            font-family: $font-body;
            
        }        
        :hover .MuiInputLabel-root {
            color: black;
            font-family: $font-body;
            
        }        
        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
            color: black;
            
        }        
        .MuiInputLabel-root.Mui-focused {
            color: black;
            
        }        
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color;
        }

    }
    
    .slash {
        width: 4px;
        height: 45px;
        background-color: $primary-color;
    }
    .color {
        color: $primary-color;
        font-family: $font-Size-title;
        
    }


    .header-title {
        color: $black-color;
       
    }
    
    .title{
        color: $primary-color;
        font-family: $font-title;
        font-weight: bold;
        
    }
   
            .Sub-title{
                color: $font-color;
                font-size: $font-size-articles;
                font-family: $font-body;
            }
            .textfield{
                font-family: $font-body;
            }
 
   
    .btn-create{
        color:white;
        width: 100%;
        font-size: $font-Size-Subtitle;
        font-family: $font-title;
            background-color: $primary-color;
        &:hover{
            background-color: $primary-color;
        }
    }
    
}