@import "../themes.scss";

.forgotpassword-page {
  width: 100%;
  height: 100vh;
  background-color: white;

  .text-field {
    background-color: #fff;
    border-radius: 6px;

    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
    }
    :hover .MuiInputLabel-root {
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .background-image {
    background-image: url("../Assets/backgroundImages.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 50%;
    position: fixed;
    opacity: 0.2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .back-btn {
    height: 10%;

    .text-back {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title-back {
        color: $primary-color;
        font-family: $font-body;
        text-transform: none;
      }
    }
  }

  .container {
    width: 100%;
    height: 85%;
    position: relative;
    display: flex;
    justify-content: center;

    .maker {
      width: 320px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .form-reset {
        border-radius: 15px;
        width: 100%;
        padding: 25px 0px 25px 0px;
        background-color: $background-color-green;

        .box-logo {
          display: flex;
          justify-content: center;
        }

        .box-text {
          display: flex;
          justify-content: center;

          .title {
            color: $primary-color;
            font-weight: bold;
            font-family: $font-body;
          }

          .sub-title {
            color: $primary-color;
            font-family: $font-body;
          }
        }

        .box-login {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 150px;
         
          background-color: #0b83c47d;

          .log {
            display: flex;
            justify-content: center;

            .icon {
              color: $primary-color;
            }

            .btn-sign-in {
              background-color: #fff;
              color: $primary-color;
              font-weight: bold;
              text-transform: none;
              font-size: 18px;
              font-family: $font-body;
              // :hover{
              //     background-color: red;
              // }
            }
          }
        }
      }
    }
  }
}
