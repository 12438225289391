@import "../../themes.scss";
.product-page {
  @include mobile-small {
    width: 400px !important;
  }
  @include mobile {
    width: 430px !important;
  }
  @include mobile-large {
    width: 450px !important;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #fff;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
  height: 80%;
  overflow: scroll;

  .title {
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }

  .simple-text {
    font-family: $font-body;
  }

  .field {
    color: $font-color;
    font-size: $font-size-note;
    font-family: $font-body;
    font-weight: bold;
  }

  .body-title {
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: $font-color;
    font-family: $font-title;
  }
  .upload {
    color: $primary-color;
    width: 140px;
    height: 140px;
    cursor: pointer;
  }

  .btn-filter {
    .MuiAutocomplete-option {
      font-family: $font-body !important;
      color: black !important;
      // font-weight: bold;
    }
  }
  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body !important;
      // font-weight: bold;
    }
    .MuiInputLabel-root {
      color: black !important;
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-body !important;
    }
    :hover .MuiOutlinedInput-input {
      font-family: $font-body !important;
    }
    :hover .MuiInputLabel-root {
      font-family: $font-body !important;
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-body !important;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      // border-color: #fff;
      font-family: $font-body !important;
    }
  }

  .btn-create {
    color: white;
    width: 100%;
    font-family: $font-title;
    font-size: $font-Size-Subtitle;

    font-size: $font-Size-Subtitle;
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
    }
  }

  .selection {
    .MuiOutlinedInput-input {
      font-family: $font-body !important;
    }
  }
}
