@import "../../themes.scss";
.releasecard-page {
  @include mobile-small {
    width: 300px !important;
  }
  @include mobile {
    width: 450px !important;
  }
  @include mobile-large {
    width: 400px !important;
  }
  @include tablet {
    width: 768px !important;
  }
  @include laptop {
    width: 1024px !important;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  background-color: #fff;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;

  border: solid gray 2px;
  overflow-x: scroll;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 90%;

  .print-layout {
    width: 100%;

    .releasecard-next {
      .logo {
        width: 100px;
        height: 100px;
      }

      .logo-text {
        color: #1b5d3c;
        font-size: 18px;
        text-align: center;
      }

      .tablevalue {
        border: 1px solid black;
        line-height: 0.1px;
      }
    }
    .address {
      .hide {
        width: 40%;
        border: none;
        box-shadow: none;
      }
      .hide {
        width: 40%;
        border: none;
        box-shadow: none;
      }
    }
    .table-releascard {
      .tablehead {
        .tablerow {
          background-color: #004269;

          .childrow {
            color: $background-menu;
            line-height: 1px;
            font-family: $font-body;
            font-weight: bold;
          }
        }
      }
    }
    .delivery {
      .delivery_child {
        font-family: $font-body;
        font-weight: bold;
        font-size: $font-size-note;
      }
    }
  }

  .modal-title {
    font-size: $font-Size-Subtitle;
    font-family: $font-title;
    color: $primary-color;
  }
}
