@import "../../themes.scss";

$primary-Color: #fafafa;
$secondary-Color: #4b4b4b;
$thirdary-Color: #f10404;
$header-Color: #ddebf7;

.tableRP {
  text-transform: capitalize;

  .header-table {
    width: 100%;
    background-color: $primary-Color;
    direction: row;
    justify-content: center;
    align-items: center;

    .grid-containner {
      height: fit-content;
      text-align: center;

      .grid-item {
        display: flex;
        justify-content: center;

        .logo {
          width: 200px;
          height: 200px;

          .title-kh {
            font-size: $font-Size-Subtitle;
            font-family: Khmer OS Muol Light;
          }

          .title-eg {
            font-size: $font-Size-Subtitle;
            font-weight: bold;
            font-family: $font-title;
          }
        }
      }

      .grid-item2 {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .kingdom {
          // width: 100%;
          text-align: center;

          .title2 {
            font-size: $font-Size-Subtitle;
            font-family: Khmer OS Muol Light;
          }

          .font-TactTeng {
            // font-family: wingding;
            font-family: "Wingdings";
          }

          .tackteng-font {
            display: flex;
            justify-content: center;

            .image-tackteng {
              width: 30%;
            }
          }

          .title-eg {
            font-size: $font-Size-Subtitle;
            font-weight: bold;
            font-family: $font-title;
          }
        }
      }
    }

    .title3 {
      font-size: $font-Size-Subtitle;
      font-family: Khmer OS Muol Light;
      // color: $secondary-color;
    }

    .title4 {
      font-size: $font-Size-Subtitle;
      // color: $secondary-color;
      font-family: $font-body;
    }
  }

  .TableContainer {
    background-color: $primary-Color;
    border-radius: 5px;
    padding: 0px 30px;
    width: 100%;
    height: 600px;
    // height: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    .tbhead {
      position: sticky !important;
      top: 0 !important;
      background-color: $primary-Color;

      .item-row {
        background-color: $header-Color;

        .title {
          color: #0969a0;
          border: none;
          font-size: 14px;
          padding: 10px;
          font-family: $font-title;
          font-weight: bold;
          border-bottom: 1px solid $primary-color;
        }
      }

      .item-row-amout {
        // background-color: $header-Color;
        .title {
          color: #0969a0;
          border: none;
          font-size: 14px;
          padding: 5px 10px;
          font-family: $font-title;
          font-weight: bold;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }

    .tbhead2 {
      .item-row2 {
        background-color: $header-Color;

        .title2 {
          // color: $secondary-Color;
          font-size: 14px;
          padding: 5px 10px;
          font-family: $font-title;
        }
      }
    }

    .bodyfooter {
      border-top: 1px solid rgba(224, 224, 224, 1);

      .tbrow {
        .tbcell {
          color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }

        .total {
          padding: 10px 10px;
          font-weight: bold;
          border: none;
          box-shadow: none;
          font-family: $font-body;
        }
      }
    }

    .bodytable {
      border-top: 0.2px solid rgba(224, 224, 224, 1);
      .tbrow {
        .tbcell {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
          // font-family: $font-body;
        }

        .title {
          padding: 10px 10px;
        }

        .tbcell-none {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }
      }

      .tbrow-body {
        .tbcell {
          border: none;
          padding: 10px 10px;
        }
      }
    }

    .bodytable-odd {
      border-top: 0.2px solid rgba(224, 224, 224, 1);
      .tbrow {
        .tbcell {
          border-bottom: none;
          padding: 10px 10px;
          // font-family: $font-body;
        }

        .tbcell-none {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }

        .title {
          padding: 10px 10px;
        }
      }

      .tbrow-body {
        .tbcell {
          border: none;
          padding: 10px 10px;
        }
      }
    }

    .bodytable2 {
      .tbrow2 {
        align-items: right;
        justify-content: content;

        .tbcell2 {
          // color: $secondary-Color;
          border-bottom: none;
        }
      }
    }

    .bodytable3 {
      .tbrow3 {
        align-items: right;
        justify-content: content;

        .tbcell3 {
          // color: $secondary-Color;
          border-bottom: none;
        }
      }
    }
  }


  
  .TableContainerPrint {
    background-color: $primary-Color;
    border-radius: 5px;
    padding: 0px 30px;
    width: 100%;
    // height: 600px;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    overflow-y: scroll;
    overflow-x: hidden;

    .tbhead {
      position: sticky !important;
      top: 0 !important;
      background-color: $primary-Color;

      .item-row {
        background-color: $header-Color;

        .title {
          color: #0969a0;
          border: none;
          font-size: 14px;
          padding: 10px;
          font-family: $font-title;
          font-weight: bold;
          border-bottom: 1px solid $primary-color;
        }
      }

      .item-row-amout {
        // background-color: $header-Color;
        .title {
          color: #0969a0;
          border: none;
          font-size: 14px;
          padding: 5px 10px;
          font-family: $font-title;
          font-weight: bold;
          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }

    .tbhead2 {
      .item-row2 {
        background-color: $header-Color;

        .title2 {
          // color: $secondary-Color;
          font-size: 14px;
          padding: 5px 10px;
          font-family: $font-title;
        }
      }
    }

    .bodyfooter {
      border-top: 1px solid rgba(224, 224, 224, 1);

      .tbrow {
        .tbcell {
          color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }

        .total {
          padding: 10px 10px;
          font-weight: bold;
          border: none;
          box-shadow: none;
          font-family: $font-body;
        }
      }
    }

    .bodytable {
      border-top: 0.2px solid rgba(224, 224, 224, 1);
      .tbrow {
        .tbcell {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
          // font-family: $font-body;
        }

        .title {
          padding: 10px 10px;
        }

        .tbcell-none {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }
      }

      .tbrow-body {
        .tbcell {
          border: none;
          padding: 10px 10px;
        }
      }
    }

    .bodytable-odd {
      border-top: 0.2px solid rgba(224, 224, 224, 1);
      .tbrow {
        .tbcell {
          border-bottom: none;
          padding: 10px 10px;
          // font-family: $font-body;
        }

        .tbcell-none {
          // color: $secondary-Color;
          border-bottom: none;
          padding: 10px 10px;
        }

        .title {
          padding: 10px 10px;
        }
      }

      .tbrow-body {
        .tbcell {
          border: none;
          padding: 10px 10px;
        }
      }
    }

    .bodytable2 {
      .tbrow2 {
        align-items: right;
        justify-content: content;

        .tbcell2 {
          // color: $secondary-Color;
          border-bottom: none;
        }
      }
    }

    .bodytable3 {
      .tbrow3 {
        align-items: right;
        justify-content: content;

        .tbcell3 {
          // color: $secondary-Color;
          border-bottom: none;
        }
      }
    }
  }


  .st-btn {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    width: 100%;

    .btn-export {
      background-color: #1976d2;
      color: $primary-Color;
      padding: 5px 0px;
      border-radius: 5px;
      width: 100px;
      margin: 20px 0px;

      &:hover {
        background-color: rgb(0, 17, 255);
      }
    }
  }
}

.selected-from {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  .field {
    .MuiOutlinedInput-input {
      // color: $gray-color !important;
      // font-family: $font-OS-Siem-Reap;
      border-radius: 10px;
      border-color: #fff;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
      border-radius: 10px;
    }
  }
}
