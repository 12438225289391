@import '../../themes.scss';

.view-user{


    @include mobile-small{
        width: 400px !important;      
    }
    @include mobile{
        width: 400px !important;
    }
    @include mobile-large{
        width: 400px !important;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 530px;    
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;
    .text-title {
        font-family: $font-title;
        font-weight: bold;
        color: $primary-color;
    }

    .sub-title{
        font-weight: bold;
        color: $primary-color; 
        font-size:$font-size-note;
        font-family:  $font-body; 
        border: none;
        box-shadow: none;
        .text-typography{
            font-family: $font-body;
            font-size: $font-size-note;
        }
       
    }
    .text-detail{
        font-family: $font-body;
        font-size: $font-size-note;
        border: none;
        box-shadow: none;

    }
    .text-email{
        font-family: $font-body; 
        font-size: $font-size-note;
        color: $primary-color; 
        border: none;
        box-shadow: none;
    }
   
}
