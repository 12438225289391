@import '../../themes.scss';

.supplies-add {

    @include mobile-small {
        width: 400px !important;
    }

    @include mobile {
        width: 430px !important;
    }

    @include mobile-large {
        width: 450px !important;
    }


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;

    .text-field {

        .MuiOutlinedInput-input {
            color: black !important;
            font-size: $font-size-note;
        }

        .MuiInputLabel-root {
            color: black !important;

        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }

        :hover .MuiOutlinedInput-input {
            color: black;
            font-family: $font-body;

        }

        :hover .MuiInputLabel-root {
            color: black;
            font-family: $font-body;

        }

        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
            color: black;

        }

        .MuiInputLabel-root.Mui-focused {
            color: black;

        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color;
        }

    }

    .upload {
        color:$primary-color;
        width: 140px;
        height: 140px;
        cursor: pointer;
    }

    .body-title {
        text-align: center;
        color: $black-color;
        font-family: $font-Size-title;
    }


    .slash {
        width: 4px;
        height: 45px;
        background-color: $primary-color;
    }

    .color {
        color: $primary-color;
        font-family: $font-Size-title;

    }


    .header-title {
        color: $black-color;

    }

    .title {
        color: $primary-color;
        font-family: $font-title;
        font-weight: bold;
    }

    .Sub-title {
        color: $font-color;
        font-size: $font-size-note;
        font-family: $font-body;
        font-weight: bold;

    }

    .textfield {
        font-family: $font-body;
    }


    .btn-create {
        color: white;
        width: 100%;
        font-size: $font-size-note;
        font-family: $font-body;
        background-color: $primary-color;

        &:hover {
            background-color: $primary-color;
        }
    }

}