@import "../../themes.scss";

.create_release {
  @include mobile-small {
    width: 500px !important;
  }
  @include mobile {
    width: 530px !important;
  }
  @include mobile-large {
    width: 550px !important;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: $background-menu;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;
  height: 600px;
  overflow: scroll;

  .modal-title {
    font-family: $font-title;
    color: $primary-color;
    font-weight: bold;
  }

  .header-title {
    font-family: $font-title;
    padding: 4px;
    font-size: $font-Size-Subtitle;
    color: $primary-color;
  }

  .container {
    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        .header-title {
        //   padding: 4px;
          border: none;
          font-family: $font-body;
          color: $font-color;
          font-size: $font-size-note;
          font-weight: bold;
        }
      }

      .body {
        box-shadow: none;

        .body-row {
          background-color: $background-table-modal;

          .body-title {
            padding: 10px;
            border: none;
            font-size: $font-Size-Subtitle;

            .status {
              font-weight: bold;
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .add-item {
    color: white;
    width: 100%;
    font-size: $font-Size-Subtitle;
    font-family: $font-title;

    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
    }

    .text-btn {
      color: $background-menu;
      font-family: $font-title;
      font-size: $font-size-articles;
    }
  }

  //     color:white;
  //     width: 100%;
  //     font-family: $font-body;
  //     font-weight: bold;

  //     font-size: $font-Size-Subtitle;
  //     // font-weight: bold;
  //         background-color: $primary-color;
  //     &:hover{
  //         background-color: $primary-color;
  //     }
  // }
  .body-title {
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
    font-weight: bold;
  }

  .btn-filter {
    .MuiAutocomplete-option {
      font-family: $font-body !important;
      color: black !important;
      // font-weight: bold;
    }
    .header-title {
      font-family: $font-body;
      color: $font-color;
      font-size: $font-size-note;
      font-weight: bold;
    }
  }

  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body !important;
      // font-weight: bold;
    }
    .MuiInputLabel-root {
      color: black !important;
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-body !important;
    }
    :hover .MuiOutlinedInput-input {
      font-family: $font-body !important;
    }
    :hover .MuiInputLabel-root {
      font-family: $font-body !important;
      color: black;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      // border-color: #fff;
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-body !important;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body !important;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      // border-color: #fff;
      font-family: $font-body !important;
    }
  }

  .form-controll-select-status {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $background-menu;
    }

    .MuiOutlinedInput-input {
      font-family: $font-body !important;
    }
    .MuiTypography-root {
      font-family: $font-body !important;
      // font-weight: bold;
    }

    .MuiSelect-icon {
      color: $gray-color;
      font-size: 28px;
    }
  }

  .text-wait {
    color: orange;
  }
  .text-allow {
    color: $primary-color;
  }
  .text-refuse {
    color: red;
  }

  .selection {
    .MuiOutlinedInput-input {
      font-family: $font-body !important;
      // font-weight: bold;
    }
  }
}
