@import "../themes.scss";

.dashboard-page {


    .slash {
        width: 4px;
        height: 45px;
        background-color: $color-member-white;
    }
    .color {
        color: $color-member-white;
        font-size: 22px;
        font-weight: bold;
        font-family: $font-title;
    }

    .container {
        margin-top: 20px;
        padding-bottom: 35px;
        
        .grid-item {      
            background-color:  rgb(255, 255, 255);
            border-radius: 15px;
        }
    }
    
}
