@import "../themes.scss";

.report-page {
  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }

  .color {
    color: $color-member-white;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .stack-btn {
    .btn-add {
      text-transform: none;
      border-radius: 6px;
      background-color: #fff;

      .MuiButton-startIcon {
        margin-right: 0px !important;
        margin-left: 0px !important;
      }

      .btn-text {
        font-weight: bold;
        margin: 0px 5px 0px 5px;
      }
    }
  }

  .date-controll {
    width: 250px;
  }

  .btn-filter {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-controll-select {
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        border-radius: 10px;
      }
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }
      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }
      .MuiMenuItem-gutters {
        font-family: $font-body;
      }
    }
  }

  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
      background-color: #fff;
      border-radius: 10px;
    }

    .MuiInputLabel-root {
      color: black !important;
      background-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
      color: black;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }

    :hover .MuiInputLabel-root {
      color: black;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }
  }

  .container {
    margin-top: 20px;
    width: 100%;

    .title-filter {
      color: $primary-color;
      font-weight: bold;
    }

    .selected-form-storage {
      background-color: #fff;
      border-radius: 5px;
      width: 250px;

      .MuiOutlinedInput-input {
        color: $gray-color !important;
        font-family: $font-body;
        border-radius: 10px;
        border-color: #fff;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }
    }

    .selected-from {
      background-color: #fff;
      border: none;
      border-radius: 10px;
      .field {
        .MuiOutlinedInput-input {
          // color: $gray-color !important;
          // font-family: $font-OS-Siem-Reap;
          border-radius: 10px;
          border-color: #fff;
        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border-color: #fff;
          border-radius: 10px;
        }

        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border-color: #fff;
          border-radius: 10px;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #fff;
          border-radius: 10px;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
          border-radius: 10px;
        }
      }
    }

    .stackdate {
      .css-1czzee8-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
        color: #808080;
      }

      .MuiInputLabel-animated {
        color: $primary-color;
      }

      .css-1f3r42k-MuiInputBase-root-MuiOutlinedInput-root {
        border: 1px solid gray;
      }

      .css-1lmg8e9-MuiInputBase-root-MuiOutlinedInput-root {
        background-color: #fff;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }

    .btn-report {
      font-weight: 500px;
      font-size: 14px;

      &:hover {
        background-color: rgb(0, 17, 255);
      }
    }
  }
}
