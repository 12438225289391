
@import "../../themes.scss";

.shop {

    @include mobile-small {
        width: 400px !important; 
      }
      @include mobile {
        width: 430px !important;
      }
      @include mobile-large {
        width: 450px !important;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;
    .text-field {
            
        .MuiOutlinedInput-input {
            color: black !important;
            font-family: $font-body;
            // font-weight: bold;
            font-size: $font-size-note;
        }   
        .MuiInputLabel-root {
            color: black !important;
        
        }        
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }        
        :hover .MuiOutlinedInput-input {
            color: black;
            font-family: $font-body;
            
        }        
        :hover .MuiInputLabel-root {
            color: black;
            font-family: $font-body;
            
        }        
        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: rgb(173, 173, 173);
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
            color: black;
            
        }        
        .MuiInputLabel-root.Mui-focused {
            color: black;
            
        }        
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $primary-color;
        }

    }
    


    .title {
        color:$primary-color;
        font-family: $font-title;
        font-weight: bold;
    }
    //past Style
    .Sub-title {
        font-size: $font-size-note;
        font-family: $font-body;
        color: $font-color;
        font-weight: bold;
    }
    .textfield {
        font-family: $font-body;
    }

    .btn-create {
        color: white;
        width: 100%;
        font-size: $font-size-note;
        font-family: $font-title;
        background-color: $primary-color;
        &:hover {
            background-color: $primary-color;
        }
    }
    
    
}
