@import '../../../themes.scss';

.requestpur{
  width: 100%;
  height: 400px;


    .toptitle{
        font-family: $font-title ;
        font-weight: 550;
        color: $primary-color;

      }
   
    .btnwait{
        background-color: $primary-color;
        width:120px;
        height: 35px;
        color: $background-menu;
        border-radius: 10px;
      
        &:hover{
            background-color: rgb(21, 185, 176);
        }
    }
  //btn view
  .st-btn {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    
    .btn-export {
      border: 1px solid $primary-color;
      color: $primary-color;
      padding: 2px 0px;
      border-radius: 5px;
      width: 80px;
      margin: 10px 0px;
      font-size: $font-size-little;
      font-family: $font-body;

      &:hover {
        background-color: $primary-color;
        color: $background-menu;
        
      }
    }
}
.main-head{


    .listitem_main{
      padding: 10px 0px 10px 0px;
      font-family: $font-body;
      font-weight: bold;
      border: none;
      box-shadow: none;
      font-size: 14px;
      color: $black-color;

    }
    .listitem{
      padding: 12px 0px 12px 0px;
      font-family: $font-body;
      border: none;
      box-shadow: none;
      font-size: 13px;
      .check-icon{
        color: $color-member-G;
    }                    

    }
    .css-15st670-MuiTableCell-root{
      border-bottom: none;
    }

  }

    .clearline{
      border: none;
      box-shadow: none;
    }



}