@import "../../themes.scss";

.body-list-materiales {
    box-shadow: none !important;

    .body-row {
        background-color: $color-text-field;

        .body-title {
            padding: 4px;
            border: none;
            font-size: $font-Size-Subtitle;
            font-family: $font-body !important;

            .status {
                font-weight: bold;
                font-family: $font-body !important;
                color: $primary-color;
            }
        }
    }

    .form-controll-select {
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: $gray-color;
        }
        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: $gray-color;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $gray-color;
        }

        .MuiOutlinedInput-input {
            font-family: $font-body !important;
        }
        .MuiTypography-root {
            font-family: $font-body !important;
            font-weight: bold;
        }
        .MuiSelect-icon {
            // color: red;
            justify-content: center !important;
        }
    }

    .btn-filter {
        .MuiAutocomplete-option {
            font-family: $font-body !important;
            color: black !important;
            // font-weight: bold;
        }
    }

    .text-field {
        .MuiOutlinedInput-input {
            color: black !important;
            font-family: $font-body !important;
        }
        .MuiInputLabel-root {
            color: black !important;
            font-family: $font-body !important;
        }
        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            font-family: $font-body !important;
        }
        :hover .MuiOutlinedInput-input {
            font-family: $font-body !important;
        }
        :hover .MuiInputLabel-root {
            font-family: $font-body !important;
            color: black;
        }
        :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            // border-color: #fff;
            font-family: $font-body !important;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
            color: black;
            font-family: $font-body !important;
        }
        .MuiInputLabel-root.Mui-focused {
            color: black;
            font-family: $font-body !important;
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            font-family: $font-body !important;
        }
    }

    .selection {
        .MuiOutlinedInput-input {
            font-family: $font-body !important;
            // font-weight: bold;
        }
    }
}
