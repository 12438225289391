@import "../../themes.scss";

.modal-user {
  // display: block;s



  .modal {

    @include mobile-small {
      width: 400px !important; 
    }
    @include mobile {
      width: 370px !important;
    }
    @include mobile-large {
      width: 400px !important;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 530px;
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;

    .text-field {
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        // font-weight: bold;
        font-size: $font-size-articles;
      }
      .MuiInputLabel-root {
        color: black !important;
      }
      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: rgb(173, 173, 173);
      }
      :hover .MuiOutlinedInput-input {
        color: #fff;
        font-family: $font-body;
      }
      :hover .MuiInputLabel-root {
        color: black;
        font-family: $font-body;
      }
      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: rgb(173, 173, 173);
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
      }
      .MuiInputLabel-root.Mui-focused {
        color: black;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }
    }

    .title {
      align-items: center;
      justify-content: space-evenly;
      color: $primary-color;
      font-family: $font-title;
      font-weight: bold;

      .upload-image {
        width: 140px;
        height: 140px;
        cursor: pointer;
      }
    }

    .body-title {
      margin-top: 2px;
      align-items: center;
      justify-content: space-evenly;
      color: $font-color;
      font-family: $font-title;
    }
    .sub-title {
      font-size: $font-size-note;
      padding-bottom: 5px;
      color: $font-color;
      font-weight: bold;
      font-family: $font-body;
    }
    .make-paper {
      flex-wrap: "wrap";
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-shadow: 15px;
      .icon-camara {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 40%;
      }
    }

    .btn-create {
      color: white;
      width: 100%;
      font-size: $font-size-note;
      font-family: $font-title;

      background-color: $primary-color;
      &:hover {
        background-color: $primary-color;
      }
    }
    .select-date {
      .MuiOutlinedInput-input {
        font-family: $font-body;
        // font-weight: bold;
        font-size: $font-size-articles;
        font-family: $font-title;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $primary-color;
      }
    }
  }
}
