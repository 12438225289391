@import "../../themes.scss";

$primary-Color: #fafafa;
$secondary-Color: #4b4b4b;
$thirdary-Color: #f10404;
$header-Color: #ddebf7;

.tablestockout {
  text-transform: capitalize;
  display: flex;
  justify-content: center;

  .print-report {
    width: 1150px;
    background-color: #fff;
    padding-bottom: 50px;

    .header-table {
      width: 100%;
      direction: row;
      justify-content: center;
      align-items: center;

      .grid-containner {
        height: fit-content;
        text-align: center;

        .grid-item {
          display: flex;
          justify-content: center;

          .logo {
            width: 200px;
            height: 200px;

            .title-kh {
              font-size: $font-Size-Subtitle;
              font-family: Khmer OS Muol Light;
            }

            .title-eg {
              font-size: $font-Size-Subtitle;
              font-weight: bold;
              font-family: $font-title;
            }
          }
        }

        .grid-item2 {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .kingdom {
            // width: 100%;
            text-align: center;

            .title2 {
              font-size: $font-Size-Subtitle;
              font-family: Khmer OS Muol Light;
            }

            .font-TactTeng {
              // font-family: wingding;
              font-family: "Wingdings";
            }

            .tackteng-font {
              display: flex;
              justify-content: center;

              .image-tackteng {
                width: 30%;
              }
            }

            .title-eg {
              font-size: $font-Size-Subtitle;
              font-weight: bold;
              font-family: $font-title;
            }
          }
        }
      }

      .title3 {
        font-size: $font-Size-Subtitle;
        font-family: Khmer OS Muol Light;
        color: $secondary-color;
      }

      .title4 {
        font-size: $font-Size-Subtitle;
        color: $secondary-color;
        font-family: $font-body;
      }
    }

    .TableContainer {
      background-color: $primary-Color;
      border-radius: 5px;
      padding: 0px 30px;
      width: 100%;
      border-collapse: separate;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-spacing: 0px;

      .tbhead {
        border-bottom: 0.2px solid $black-color;
        .item-row {
          background-color: $header-Color;

          .title {
            color: #0969a0;
            border: none;
            font-size: 14px;
            padding: 10px;
            font-family: $font-title;
            font-weight: bold;
          }
        }

        .item-row-amout {
          // background-color: $header-Color;
          .title {
            color: #0969a0;
            border: none;
            font-size: 14px;
            padding: 5px 10px;
            font-family: $font-title;
            font-weight: bold;
            border-bottom: 1px solid rgba(224, 224, 224, 1);
          }
        }
      }

      .tbhead2 {
        .item-row2 {
          background-color: $header-Color;

          .title2 {
            color: $secondary-Color;
            font-size: 14px;
            padding: 5px 10px;
            font-family: $font-title;
          }
        }
      }

      .bodyfooter {
        border-top: 1px solid rgba(224, 224, 224, 1);

        .tbrow {
          .tbcell {
            color: $secondary-Color;
            border-bottom: none;
            padding: 10px 10px;
          }

          .total {
            padding: 10px 10px;
            font-weight: bold;
            border: none;
            box-shadow: none;
          }
        }
      }

      .bodytable {
        .tbrow {
          .tbcell {
            color: $secondary-Color;
            border-bottom: none;
            padding: 10px 10px;
          }

          .title {
            padding: 10px 10px;
          }

          .tbcell-none {
            color: $secondary-Color;
            border-bottom: none;
            padding: 10px 10px;
          }
        }

        .tbrow-body {
          .tbcell {
            border: none;
            padding: 10px 10px;
          }
        }
      }

      .bodytable-odd {
        .tbrow {
          .tbcell {
            color: $secondary-Color;
            border-bottom: none;
            padding: 10px 10px;
            background-color: $header-Color;
          }

          .tbcell-none {
            color: $secondary-Color;
            border-bottom: none;
            padding: 10px 10px;
          }

          .title {
            padding: 10px 10px;
          }
        }

        .tbrow-body {
          .tbcell {
            border: none;
            padding: 10px 10px;
          }
        }
      }

      .bodytable2 {
        .tbrow2 {
          align-items: right;
          justify-content: content;

          .tbcell2 {
            color: $secondary-Color;
            border-bottom: none;
          }
        }
      }

      .bodytable3 {
        .tbrow3 {
          align-items: right;
          justify-content: content;

          .tbcell3 {
            color: $secondary-Color;
            border-bottom: none;
          }
        }
      }
    }
  }

  .st-btn {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    width: 100%;

    .btn-export {
      background-color: #1976d2;
      color: $primary-Color;
      padding: 5px 0px;
      border-radius: 5px;
      width: 100px;
      margin: 20px 0px;

      &:hover {
        background-color: rgb(0, 17, 255);
      }
    }
  }
}
