@import "../../themes.scss";

.delete-supplies {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #fff;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;

  .title {
    color: $primary-color;
    font-family: $font-title;
    font-size: $font-Size-title;
  }
  .simple-text {
    font-family: $font-body;
    // font-weight: bold;
  }
  .body-text {
    color: $primary-color;
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
  }

  .btn-delete {
    font-family: $font-title;
    font-size: $font-Size-Subtitle;
  }

  .btn-void {
    background-color: red;
    color: white;
    border: none;
    font-family: $font-body;
    font-size: $font-Size-Subtitle;
  }

  .text-input {
    .MuiOutlinedInput-input {
      font-family: $font-title;
      font-size: $font-size-articles;
      color: $primary-color;
    }
  }
}
