@import "../../themes.scss";

.icon-menu-update{
    color: $color-member-O;
}

.icon-menu-delete{
    color: $color-member-R;
}

.text-menu{
    color: $gray-color;
    font-family: $font-body;
    
}

