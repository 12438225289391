@import "../../themes.scss";

.log-out-style {
  .MuiPaper-root {
    border-radius: 12px !important;
  }
}

.drawer-menu {

  .MuiDrawer-paper {
    // background: $background-menu;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),url("../../Assets/Slide_template_bg.png");
    background-size: 500%;
    background-position: 50% 50%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: none;
    box-shadow: 0px 15px 15px rgba(42, 173, 254, 0.25);
  }

  .list-menu {
    .list-item-active {
      border-left: 4px solid $primary-color;
      background-color: rgb(225, 235, 253);
      display: block;

      .list-item-button {
        max-height: 48px;
        justify-content: initial;

        .list-item-icon {
          max-width: 12px;
          justify-content: center;
          opacity: 1;
          color: $primary-color;
        }

        .list-item-text {
          opacity: 1;
          color: $primary-color;
          font-family: $font-title;
          font-size: $font-Size-Subtitle;
          padding-top: 5px;
          font-weight: bold;
        }

        // menu active
        .list-item-icon-active {
          max-width: 35px;
          justify-content: center;
          color: $primary-color;
        }

        .list-item-text-active {
          opacity: 1;
          font-family: $font-title;
          color: $primary-color;
          font-weight: bold;
        }
      }

      .list-item-button-active {
        max-height: 48px;
        justify-content: initial;
        background-color: $background-menu-active-b;
        border-left: 4px solid $primary-color;

        .list-item-icon {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          opacity: 0.5;
        }

        .list-item-text {
          opacity: 1;
          color: $primary-color;
          font-family: $font-title;
        }

        // menu active
        .list-item-icon-active {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          color: $primary-color;
          font-weight: bold;
        }

        .list-item-text-active {
          opacity: 1;
          color: $primary-color;
        }
      }
    }

    .list-item {
      display: block;

      .list-item-button {
        max-height: 48px;
        justify-content: initial;

        .list-item-icon {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          opacity: 1;
          color: $black-color;
        }

        .list-item-text {
          opacity: 1;
          color: $black-color;
          // font-weight: bold;
          font-family: $font-title;
          font-size: $font-Size-Subtitle;
          padding-top: 5px;
        }

        // menu active
        .list-item-icon-active {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          color: $primary-color;
          font-weight: bold;
        }

        .list-item-text-active {
          opacity: 1;
          font-family: $font-title;
          color: $primary-color;
        }
      }

      .list-item-button-active {
        max-height: 48px;
        justify-content: initial;
        background-color: $background-menu-active-b;
        border-left: 4px solid $primary-color;

        .list-item-icon {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          opacity: 0.5;
        }

        .list-item-text {
          opacity: 1;
          color: $black-color;
          font-family: $font-title;
        }

        // menu active
        .list-item-icon-active {
          max-width: 35px;
          // margin-right: 10px;
          justify-content: center;
          color: $primary-color;
          font-weight: bold;
        }

        .list-item-text-active {
          opacity: 1;
          color: $primary-color;
        }
      }
    }
  }
}

.profile-header {
  background-color: $color-text-field;
  border-radius: 5px;
}
