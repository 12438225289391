@import "../../themes.scss";

.alert-message {
  margin-top: 10px;
  .snackbar-alert {
    border-radius: 10px;
    padding: 0px;

    .alert-info {
      border-radius: 10px;
      font-weight: bold;
    }

    .alert-success {
      border-radius: 10px;
      background-color: #00c9a7;
      color: #fff;
    }

    .alert-error {
      border-radius: 10px;
      background-color: #ff6f91;
      color: #fff;
    }
  }
  .btn {
    text-align: center;
    place-content: center;
    text-decoration: underline;
    color: #fff;
  }
  .MuiSvgIcon-root {
    background-color: #ed6c02;
    color: #ed6c02;
    margin-right: 10px;
  }
  .css-1vooibu-MuiSvgIcon-root {
    color: #ed6c02;
  }
  .MuiAlert-root {
    padding: 0px;
    margin-right: 0px;
  }
  .MuiAlert-icon {
    margin: 0px;
  }
  .MuiButtonBase-root-MuiIconButton-root {
    padding: 0px;
    margin-left: 0px;
  }
  // .css-ki1hdl-MuiAlert-action {
  //   padding: 0px;
  //   margin-left: 0px;
  // }
}
