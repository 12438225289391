@import "../../../themes.scss";

.invetory-dashboard {
  width: 100%;
  padding: 20px;

  .main-title {
    color: #0b82c4;
    font-size: $font-size-articles;
    font-family: $font-body;
    font-weight: bold;
    padding: 10px;
  }

  .grid-group {
    width: 100%;
    height: 100%;

    .card-sammary {
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      justify-content: center;
    }
  }

  .sub-title{
    font-family: $font-body;
    font-size: $font-size-small;
    text-align: center;
  }

  .number {
    color: $primary-color;
    text-align: center;
    font-weight: bold;
    font-family: $font-body;
  }

  .num-over {
    color: $primary-color;
    font-weight: bold;
    font-family: $font-body;
  }
}
