@import "../../themes.scss";

.delete-supplies {
  .title {
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }
  .body-title {
    color: $primary-color;
    font-size: $font-size-note;
    font-family: $font-body;
  }
  .text {
    font-size: $font-size-note;
    font-family: $font-body;
    color: $primary-color;
    font-weight: bold;
  }

  .simple-text {
    font-family: $font-body;
    font-size: $font-size-note;
    // font-weight: bold;
}
  .btn-void {
    background-color: red;
    color: white;
    border: none;
    font-size: $font-size-note;
    font-family: $font-title;
    :hover{
      background-color: red;
    }
  }
  .body-sub {
    font-family: $font-body;
    font-size: $font-size-note;
  }
  .delete {
    font-size: $font-size-note;
    font-family: $font-title;
    font-weight: bold;
  }
  
  .body-text {
    color: $primary-color;
    font-family: $font-title; 
    font-size: $font-Size-Subtitle;
    font-weight: bold;
}

  .text-input {
    .MuiOutlinedInput-input {
      font-family: $font-title;
      font-size: $font-size-note;
      color: $primary-color;
    }
  }
}
