@import "../../../themes.scss";
.datap-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 400px;

  .btn-penddng {
    text-transform: none;
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }

  .tbcontainner {
    width: 100%;
    .tbheader {
      .tbrow {
        .tbcell {
          padding: 10px 0px 10px 0px;
          border: none;
          font-size: $font-size-small;
          font-family: $font-body;
          color: $black-color;
          font-weight: bold;
        }
      }
    }

    .css-kuqe2n-MuiTableCell-root {
      border-bottom: none;
    }

    .tbbody {
      .tbcell {
        padding: 12px 0px 12px 0px;
        border: none;
        box-shadow: none;
        font-size: $font-size-little;
        font-family: $font-body;
        .check-icon {
          color: $color-member-G;
        }
      }

      .color {
        color: $color-member-R;
        font-size: $font-size-note;
      }
    }
    .listitem {
      padding: 12px 0px 12px 0px;
      text-align: center;
      height: 200px;
      color: gray;
      font-family: $font-body;
      border: none;
      font-weight: bold;
      box-shadow: none;
      font-size: $font-size-little;

      .sub-title {
        font-family: $font-body;
        font-size: $font-size-small;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .body-text {
    font-family: $font-body;
    font-size: $font-size-small;
    text-align: center;
  }

  //btn view
  .st-btn {
    display: flex;
    justify-content: right;
    align-items: flex-end;

    .btn-export {
      border: 1px solid $primary-color;
      color: $primary-color;
      padding: 2px 0px;
      border-radius: 5px;
      width: 80px;
      margin: 10px 0px;
      font-size: $font-size-little;

      &:hover {
        background-color: $primary-color;
        color: $background-menu;
      }
    }
  }

  .siznumbertop {
    font-size: 30px;
  }

  .title {
    color: $primary-color;
    font-size: $font-size-articles;
    font-family: $font-title;
    font-weight: bold;
  }
  .numberproduct {
    color: $color-member-O;
    font-family: $font-title;
    font-weight: bold;
  }
  .newproduct {
    color: $color-member-R;
    font-family: $font-title;
  }
  .repareproduct {
    color: $primary-color;
  }
  .not {
    color: $color-member-R;
  }
}
