@import '../../themes.scss';

.delete-supplies {

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 520px;    
    // background-color: #fff;
    // box-shadow: 30px;
    // border-radius: 10px;
    // padding: 20px;

    .title{
        color: $primary-color;
        font-family: $font-title;
        font-weight: bold;
          
    }
    .simple-text {
        font-family: $font-body;
    }
    .body-text {
        color: $primary-color;
        font-family: $font-title; 
        font-size: $font-Size-Subtitle;
        font-weight: bold;
    }

    .btn-delete{
        font-family: $font-title; 
        font-size: $font-Size-Subtitle;
        font-weight: bold;
    }
    
    .btn-void {           
        background-color: red;
        color: white;
        border: none;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
    }

    .text-input{
        .MuiOutlinedInput-input {
            font-family: $font-title;   
            font-size: $font-size-articles;  
            color: $primary-color;
            font-weight: bold;
        } 
    }
  
}