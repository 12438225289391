
// theme color
$color-text-field: #d2ecfb;


// -----------Color
$secondary-color: #3C64F6;
$font-color:#3D3D3D;
$color-member-O:orange;
$color-member-R:red;
$color-member-G:#2AEA00;
$color-member-LG:greenyellow;
$color-member-white: #0f81c2;


$black-color: #3D3D3D;
$gray-color: gray;
$primary-color:#0f81c2;



//background Color
$background-menu : #fff;
$background-menu-dark : black;
$background-table-modal : #def6d9;
$background-color: #0969A0;
$background-menu-active: #eefbeb;
$background-menu-active-b:#d2ecfb;
$background-color-green:#F8F8F8;

//color background partern #E3F2FD

//over 
$background-hover:#f1f8fa; 

//font 
$font-title : "Century Gothic" !important;
$font-body : "Century Gothic" !important; 
// $font-bodyKH : "Kantumruy" !important; //Kantumruy
// $font-english-title : "Helvetica" !important; //Gothic A1
$font-OS-Siem-Reap: "Siemreap" !important;


//font-size
$font-Size-title: 22px;
$font-Size-body: 19px;
$font-Size-Subtitle: 17px;
$font-size-articles: 16px;
$font-size-note: 15px;
$font-size-small: 14px;
$font-size-little: 13px;

//font-size-icon
$font-size-icon: 25px;


// Login Page

@mixin desktop {
    @media (min-width: #{1025px}) and (max-width: #{1650px}) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: #{769px}) and (max-width: #{1024px}) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: #{426px}) and (max-width: #{768px}) {
        @content;
    }
}

@mixin mobile-large {
    @media (min-width: #{426px}) and (max-width: #{600px}) {
        @content;
    }
}

@mixin mobile {
    @media (min-width: #{377px}) and (max-width: #{425px}) {
        @content;
    }
}

@mixin mobile-small {
    @media (min-width : #{0px}) and (max-width: #{376px}) {
        @content;
    }
}

@font-face {
    font-family: TactTeng;
    src: url('./Assets/assets/TACTENG.TTF');
}

@font-face {
    font-family: wingding;
    src: url('./Assets/assets/wingding.ttf');
}
  
