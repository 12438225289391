@import "../themes.scss";

.user-page {
  .contanner-header {
    .text-field {
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
      }

      .MuiInputLabel-root {
        color: black !important;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }

      :hover .MuiOutlinedInput-input {
        color: #fff;
        font-family: $font-body;
      }

      :hover .MuiInputLabel-root {
        color: black;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: black;
      }

      .MuiInputLabel-root.Mui-focused {
        color: black;
        font-family: $font-body;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }

    .slash {
      width: 4px;
      height: 45px;
      background-color: $color-member-white;
    }

    .color {
      color: $color-member-white;
      font-size: $font-Size-title;
      font-family: $font-title;
      font-weight: bold;
    }

    .stack-btn {
      .btn-add {
        text-transform: none;
        justify-content: center;
        border-radius: 10px;
        background-color: $primary-color;
        color: #fff;
        width: 100px;
        padding: 10px;

        .btn-text {
          color: #fff;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }
    }

    .btn-search {
      border-radius: 5px;
      background-color: #fff;

      .btn-text-field {
        display: flex;
        align-items: center;
        width: 300px;
        background-color: #fff;
        border-radius: 10px;
        font-family: $font-body;
      }
    }
  }

  .container {
    margin-top: 20px;
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;

      @include mobile-small {
        width: 500px !important;
      }

      @include mobile {
        width: 530px !important;
      }

      @include mobile-large {
        width: 550px !important;
      }

      @include tablet {
        width: 768px !important;
      }

      .header-row {
        border: 0.2px solid $black-color;

        .header-title {
          padding: 10px;
          border-bottom: 1px solid $primary-color;
          font-size: $font-size-note;
          color: $black-color;
          font-weight: bold;
        }
      }

      .body {
        box-shadow: none;
        background-color: $background-hover;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;

          .avatar {
            width: 40px;
            height: 40px;
            text-align: left;
          }

          .product-name {
            place-items: center;
            font-family: $font-body;
            font-size: $font-size-note;
            display: flex;
            width: 70%;
          }
        }

        .body-english {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }

      .body-odd {
        box-shadow: none;
        background-color: #fff;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;

          .avatar {
            width: 40px;
            height: 40px;
            text-align: left;
          }

          .product-name {
            place-items: center;
            font-size: $font-size-note;
            font-family: $font-body;
            display: flex;
            width: 70%;
          }
        }

        .body-english {
          padding: 6px 10px;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }
    }
  }
}
