@import "../../../themes.scss";

.product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;

  @include mobile-large {
    width: 600px !important;
  }
  @include tablet {
    width: 768px !important;
  }
  @include laptop {
    width: 1024px !important;
  }

  .main-title {
    color: #0b82c4;
    font-family: $font-title;
    font-size: $font-size-articles;
    font-weight: bold;
    padding: 10px;
  }

  .css-15mgse8-MuiStack-root {
    display: flex;
    align-items: flex-end;
  }

  .number {
    color:$primary-color;
    text-align: center;
    font-weight: bold;
    font-family: $font-body;
  }

  .num-over {
    color:$primary-color;
    font-weight: bold;
    display: flex;
    font-family: $font-body;
    display: flex;
    justify-content: center;    
  }

  .sub-title{
    font-family: $font-body;
    font-size: $font-size-small;
    text-align: center;
  }

  
  .sub-body{
    font-family: $font-body;
    font-size: $font-size-small;
    text-align: center;
  }

  .grid-group {
    width: 100%;
    height: 100%;

    .card-productdetail {
      background-color: #f8f8f8;
      border-radius: 18px;
      width: 100%;
      // height: 195px;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      justify-content: center;
    }
   
  }

  .grid-over {
    width: 100%;
    height: 100%;

    .card-overview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #f8f8f8;
      border-radius: 18px;
      width: 100%;
      padding: 20px;
    }
  }
}
