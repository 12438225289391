@import "../themes.scss";

.rawmaterialofproduct-pages {
  @include mobile-small {
    width: 500px !important;
  }

  @include mobile {
    width: 530px !important;
  }

  @include mobile-large {
    width: 550px !important;
  }

  @include tablet {
    width: 768px !important;
  }

  .text-field {
    font-family: $font-body;
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
      // font-weight: bold;
    }

    .MuiInputLabel-root {
      color: black !important;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    :hover .MuiOutlinedInput-input {
      color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }

    :hover .MuiInputLabel-root {
      color: black;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }
  }

  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }

  .color {
    color: $color-member-white;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .btn-add {
    border-radius: 5px;
    background-color: white;

    .style-add {
      color: $primary-color;
      font-size: $font-Size-Subtitle;
      font-weight: bold;
    }
  }

  .btn-search {
    .btn-text-field {
      display: flex;
      align-items: center;
      width: 300px;
      background-color: #fff;
      border-radius: 10px;
      font-family: $font-body;

      @include mobile-small {
        width: 150px !important;
      }

      @include mobile {
        width: 200px !important;
      }

      @include mobile-large {
        width: 250px !important;
      }

      @include tablet {
        width: 300px !important;
      }
    }
  }

  .btn-filter {
    width: 18%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-controll-select {
      font-family: $font-body;

      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }
    }
  }

  .btn {
    .btn-add-style {
      border-radius: 10px;
      text-transform: none;
      background-color: $primary-color;
      color: #fff;
      width: 100px;
      padding: 10px;

      @include mobile-small {
        display: none;
      }

      @include mobile {
        display: none;
      }

      @include mobile-large {
        display: none;
      }

      .style-add {
        color: #fff;
        font-size: $font-size-note;
        font-family: $font-body;

        @include mobile-small {
          display: none;
        }

        @include mobile {
          display: none;
        }

        @include mobile-large {
          display: none;
        }
      }
    }
  }

  .btn-threeicon {
    display: none;

    @include mobile-small {
      display: block;
    }

    @include mobile {
      display: block;
    }

    @include mobile-large {
      display: block;
    }

    .vert-icon {
      color: $secondary-color;
    }

    .maker-menu {
      color: $gray-color;
      font-weight: 500;
      padding: 0px;
    }

    .btn-add-style {
      border-radius: 10px;
      text-transform: none;
      background-color: $primary-color;
      color: #fff;
    }

    .style-add {
      color: #fff;
      font-size: $font-Size-Subtitle;
      font-family: $font-body;
      font-weight: bold;
    }
  }

  .container {
    width: 100%;
    overflow-x: auto;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .image {
      opacity: 1;
      display: block;
      width: 20%;
      height: auto;
      transition: 0.5s ease;
      backface-visibility: hidden;

      :hover {
        opacity: 0.3;
      }
    }

    @include mobile-small {
      width: 500px !important;
    }

    @include mobile {
      width: 530px !important;
    }

    @include mobile-large {
      width: 550px !important;
    }

    @include tablet {
      width: 768px !important;
    }

    margin-top: 20px;

    .table-head {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;
     

      .header-row {
        border: 0.2px solid $black-color;

        .header-title {
          padding: 10px 10px;
          border-bottom: 1px solid $primary-color;
          font-size: $font-size-note;
          color: $black-color;
          font-family: $font-title;
          font-weight: bold;
        }
      }

      .body-active {
        box-shadow: none;
        cursor: pointer;
        background-color: $background-hover;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;
          
          .avatar {
            width: 40px;
            height: 40px;
          }

          .product-name {
            place-items: center;
            display: flex;
            width: 100%;
          }
        }
      }

      .body {
        box-shadow: none;
        cursor: pointer;
        background-color:$background-hover;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;

          .avatar {
            width: 40px;
            height: 40px;
          }

          .product-name {
            place-items: center;
            display: flex;
            width: 100%;
          }
        }
      }

      .body-odd {
        box-shadow: none;
        cursor: pointer;
        background-color: #fff;

        .body-title {
          padding: 6px 10px;
          font-size: $font-size-note;

          .avatar {
            width: 40px;
            height: 40px;
          }

          .product-name {
            place-items: center;
            display: flex;
            width: 100%;
          }
        }
      }
    }
    .pagination{
      margin-top: 15px;
      justify-content: right;
      padding: 0px 30px;
    }
  }
}
