@import '../../themes.scss';

.view-supplies {

    @include mobile-small{
        width: 400px !important;      
    }

    @include mobile{
        width: 430px !important;
    }
    @include mobile-large{
        width: 450px !important;
    } 


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;    
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;

    .text-title {
        font-family: $font-title;
        color: $primary-color;
        font-weight: bold;
    }
    .sub-title{
        width: 40%;
        color:$primary-color; 
        font-size:$font-size-note;
        font-family:  $font-body; 
        border: none;
        box-shadow: none;
        .text-typography{
            color:$primary-color; 
            font-family: $font-body;
            font-size: $font-size-note;
        }
    }
    .avatar-make{
        // align-content: flex-start;
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 120px;
        height: 120px;
    }
    .text-detail{
        font-family: $font-body;
        font-size: $font-size-note;
        border: none;
        box-shadow: none;

    }

    .text-email{
        font-family: $font-body; 
        font-size: $font-Size-Subtitle;
        color: $primary-color; 
        border: none;
        box-shadow: none;
    }
    
  
}