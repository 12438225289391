@import "../themes.scss";

.releasecard-pages {
  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-family: $font-body;
    }

    .MuiInputLabel-root {
      color: black !important;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    :hover .MuiOutlinedInput-input {
      color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }

    :hover .MuiInputLabel-root {
      color: black;
    }

    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-body;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      border-radius: 10px;
    }
  }

  // .css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
  //   color: black !important;
  //   font-family: $font-body;
  // }
  .css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
    color: black !important;
    font-family: $font-body;
  }

  .btn-filter {
    .MuiAutocomplete-option {
      font-family: $font-body !important;
    }
  }

  .slash {
    width: 4px;
    height: 45px;
    background-color: $color-member-white;
  }

  .color {
    color: $color-member-white;
    font-size: $font-Size-title;
    font-family: $font-title;
    font-weight: bold;
  }

  .btn {
    .btn-add-style {
      text-transform: none;
      border-radius: 10px;
      background-color: $primary-color;
      color: #fff;
      padding: 10px;
      width: 100px;


      .style-add {
        color: #fff;
        font-size: $font-size-note;
        font-family: $font-body;
      }
    }
  }

  .btn-search {
    border-radius: 10px;
    background-color: #fff;

    .btn-text-field {
      display: flex;
      align-items: center;
      width: 300px;
      background-color: #fff;
      border-radius: 10px;
      font-family: $font-body;
    }
  }

  .btn-filter {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-controll-select {
      .MuiOutlinedInput-input {
        color: black !important;
        font-family: $font-body;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        border-radius: 10px;
      }

      .MuiMenuItem-gutters {
        font-family: $font-body;
      }
    }
  }

  .container {
    margin-top: 20px;
    overflow-x: auto;
    background-color: #fff;
    padding-bottom: 30px;
    border-radius: 10px;

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 0px;

      .header-row {
        border: 0.2px solid $black-color;
        .header-title {
          padding: 10px 10px;
          border-bottom: 1px solid $primary-color;
          font-size: $font-size-note;
          color: $black-color;
          font-family: $font-title;
          font-weight: bold;
        }
      }

      .body {
        box-shadow: none;
        background-color: #fff;

        :hover {
          background-color: $background-hover;
        }

        cursor: pointer;

        .body-title {
          padding: 6px 10px;
          // border: none;
          font-size: $font-size-note;
          font-family: $font-body;
       
        }
      }

      .body-active {
        box-shadow: none;

        background-color: $background-hover;

        cursor: pointer;

        .body-title {
          padding: 6px 10px;
          // border: none;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }

      .body-odd {
        box-shadow: none;
        // background-color: $color-text-field;

        :hover {
          background-color: $background-hover;
        }

        cursor: pointer;

        .body-title {
          padding: 6px 10px;
          // border: none;
          font-size: $font-size-note;
          font-family: $font-body;
        }
      }

      .void-made {
        box-shadow: none;

        .voiced-body-row {
          background-color: rgb(253, 237, 237);
          opacity: 60%;
          cursor: pointer;

          .voiced-body-title {
            padding: 6px 10px;
            // border: none;
            font-size: $font-size-note;
            font-family: $font-body;
            font-weight: bold;

            .btn-void {
              color: #000;
              font-family: $font-body;
              font-weight: bold;
              border-color: rgb(250, 165, 165);
            }
          }
        }
      }

      .void-odd {
        box-shadow: none;
        background-color: rgb(253, 237, 237);
        cursor: pointer;

        .voiced-body-row {
          opacity: 60%;

          .voiced-body-title {
            padding: 6px 10px;
            // border: none;
            font-size: $font-size-note;
            font-family: $font-body;
            font-weight: bold;

            .btn-void {
              color: #000;
              font-family: $font-body;
              font-weight: bold;
              border-color: rgb(250, 165, 165);
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 15px;
      justify-content: right;
      padding: 0px 30px;
    }
  }

  .typography-style {
    font-size: $font-size-note;
    font-family: $font-body;
    color: $black-color;
    font-weight: bold;
  }
  .typography-No{
    font-size: $font-size-note;
    font-family: $font-body;
    color: red;
    font-weight: bold;
  }
}
