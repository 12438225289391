@import "../../themes.scss";

.view-product {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: #fff;
  // box-shadow: 30px;
  border: none;
  border-radius: 10px;
  padding: 20px;

  .stack_call{
    justify-content: center;
    display: flex;
    place-items: center;
  }

  .header-title {
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }

  .text-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-Size-title;
  }
  .sub-title {
    color: $primary-color;
    font-size: $font-size-note;
    font-family: $font-body;
    border: none;
    box-shadow: none;
    .text-typography{
      font-family: $font-body;
    }
  }
  .text-detail {
    font-family: $font-body;
    font-size: $font-size-note;
    border: none;
    box-shadow: none;
    .text{
      font-family: $font-title;
    }
  }
}

.hoverpop {
  transition: 60s ease;
  opacity: 5;
  position: relative;

  .image {
    opacity: 5;
    display: block;
    width: 20%;
    height: auto;
    transition: 60s ease;
    transform: translate(-0%, -10%);
    // backface-visibility: hidden;
    :hover {
      opacity: 0.7;
    }
  }

}
