@import "../../themes.scss";

.purchase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  background-color: #fff;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 20px;
  height: 550px;
  overflow: scroll;

  @include mobile-small {
    width: 400px !important;
  }

  @include mobile {
    width: 430px !important;
  }

  @include mobile-large {
    width: 450px !important;
  }

  .text-field {
    .MuiOutlinedInput-input {
      color: black !important;
      font-weight: 300;
    }

    .MuiInputLabel-root {
      color: black !important;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: rgb(173, 173, 173);
    }
    :hover .MuiOutlinedInput-input {
      color: black;
      // font-family: $font-bodyKH;
      font-weight: 300;
    }
    :hover .MuiInputLabel-root {
      color: black;
      font-family: $font-body;
      font-weight: 300;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: rgb(173, 173, 173);
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
    }
  }

  .form-controll-select {
    .MuiOutlinedInput-input {
      color: $primary-color;
      font-family: $font-body;
    }
  }

  .title {
    font-family: $font-title;
    font-weight: bold;
    color: $primary-color;
  }

  .body {
    font-family: $font-body;
    font-size: $font-size-note;
    font-weight: bold;
    color: $primary-color;
  }

  .field {
    font-family: $font-body;
    font-size: $font-size-note;
    font-weight: bold;
    color: $black-color;
  }

  .Sub-title {
    font-size: $font-size-note;
    color: $font-color;
    font-family: $font-body;
  }

  .textfield {
    font-family: $font-body;
    font-weight: bold;
  }

  .btn-create {
    color: white;
    width: 100%;
    font-size: $font-size-note;
    font-family: $font-title;
    background-color: $primary-color;
    &:hover {
      background-color: $primary-color;
    }
  }

  .add-item {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color;
    }

    .text-btn {
      color: $background-menu;
      font-family: $font-title;
      font-size: $font-size-articles;
    }
  }

  .container {
    @include mobile-small {
      width: 370px !important;
    }
    @include mobile {
      width: 370px !important;
    }
    @include mobile-large {
      width: 400px !important;
    }

    .table {
      width: 100%;
      border-collapse: separate;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-spacing: 0px 8px;

      .header-row {
        .header-title {
          padding: 0;
          border: none;
          font-size: $font-size-note;
          font-weight: bold;
          color: $black-color;
        }
      }

      .body {
        box-shadow: none;

        .body-row {
          background-color: $background-table-modal;

          .body-title {
            padding: 10px;
            border: none;
            font-size: $font-size-note;
           
            .status {
              font-weight: bold;
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  .sub-title {
    color: $black-color;
    font-size: $font-size-note;
    font-family: $font-body;
    font-weight: bold;
    border: none;
    box-shadow: none;
    padding: 0;
    margin-top: 25px;

    .text-typography {
      font-family: $font-body;
      font-size: $font-size-note;
      font-weight: bold;
      color: $black-color;
    }
  }
  .text-detail {
    font-family: $font-body;
    font-size: $font-size-note;
    border: none;
    box-shadow: none;
  }
}
