@import "../../themes.scss";

.view-purchase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  background-color: #fff;
  box-shadow: 30px;
  border-radius: 10px;
  padding: 0px;
  height: 550px;
  overflow: scroll;
}

.sub-title {
  font-family: $font-body;
}

.btn-maker {
  .btn-reject {
    background-color: $color-member-R;
    width: 100px;

    &:hover {
      background-color: $color-member-R;
    }

    color: white;
  }

  .btn-app {
    background-color: $primary-color;
    width: 100px;

    &:hover {
      background-color: $primary-color;
    }

    color: white;
  }
}

.text-field {
  .MuiOutlinedInput-input {
    color: black !important;
    font-family: $font-body;
    font-size: $font-size-articles;
    font-weight: 300;
  
  }

  .MuiInputLabel-root {
    color: black !important;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgb(173, 173, 173);
  }
  :hover .MuiOutlinedInput-input {
    color: black;
    // font-family: $font-bodyKH;
    font-weight: 300;
  }
  :hover .MuiInputLabel-root {
    color: black;
    font-family: $font-body;
    font-weight: 300;
  }
  :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgb(173, 173, 173);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: black;
  }
  .MuiInputLabel-root.Mui-focused {
    color: black;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color;
  }
}

.amount {
  color: $primary-color;
  font-weight: bold;
  font-family: $font-body;
}
.style-text {
  font-family: $font-body;
}

.tage-name {
  font-family: $font-body;
}
