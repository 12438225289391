@import '../../themes.scss';

.delete-shop {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;    
    background-color: #fff;
    box-shadow: 30px;
    border-radius: 10px;
    padding: 20px;


    .title{
        color: $primary-color;
        font-family: $font-title;
        // font-size: $font-Size-title;
        font-weight: bold;
          
    }
    .simple-text {
        font-family: $font-body;
        font-size: $font-size-note;
        // font-weight: bold;
    }
    .body-text {
        color: $primary-color;
        font-family: $font-title; 
        font-size: $font-Size-Subtitle;
        font-weight: bold;
    }

    .btn-delete{
        font-family: $font-title; 
        font-size: $font-Size-Subtitle;
        font-weight: bold;
    }
    
    .btn-void {           
        background-color: red;
        color: white;
        border: none;
        font-size: $font-Size-Subtitle;
        font-weight: bold;
        font-family: $font-body;
    }

    .text-input{
        .MuiOutlinedInput-input {
            font-family: $font-title;   
            font-size: $font-size-articles;  
            color: $primary-color;
            font-weight: bold;
        } 
    }
}